// *IMPORTANT* npm install bootstrap@4.3.1 *IMPORTANT*

const release_date = new Date("09/21/2022 00:00:00");

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-table/dist/bootstrap-table.min.css";

/* Application Template CSS overrides                                         */
/* ***** IMPORTANT ***** */
/* This file came from the now obsolete toolkit.js, and got checked in below  */
/* DO NOT delete these files from the depot until we figure out how to get    */
/* rid of this file                                                           */
import "./content/dist/toolkit.min.css";

import "jquery-ui/themes/base/all.css";

import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-table/dist/bootstrap-table.min.js";

/*
import Popper from "popper.js/dist/popper.js";
import "../ext/bootstrap-tour/js/bootstrap-tour-standalone.js";
import "../ext/bootstrap-tour/css/bootstrap-tour-standalone.css";
*/

import "./content/assets/js/application.js";
import "./content/assets/css/application.css";

import img_brand_white from "./content/assets/img/brand-white.png";

(window.$ = require("jquery")),
  /* require("jquery-ui"), */
  require("jquery-ui/ui/widgets/sortable");
window.Cookies = require("js-cookie");
$(document).ready(function () {
  $('[data-toggle="dropdown"]').tooltip({
    html: true,
    sanitize: false,
    trigger: "hover",
  });
  $('[data-toggle="modal"]').tooltip({
    html: true,
    sanitize: false,
    trigger: "hover",
  });
  $('[data-toggle="tab"]').tooltip({
    html: true,
    sanitize: false,
    trigger: "hover",
  });
  $('[data-toggle="tooltip"]').tooltip({
    html: true,
    sanitize: false,
    trigger: "hover",
  });

  let release_notes_read = false;

  const release_notes_last_viewed = Cookies.get("release_notes_last_viewed");
  console.debug("release_notes_last_viewed " + release_notes_last_viewed);
  if (release_notes_last_viewed) {
    const rnlv = new Date(release_notes_last_viewed);
    console.debug("rnlv " + rnlv);
    if (rnlv) {
      console.debug(
        "release_date " + release_date.toString() + ", rnlv " + rnlv.toString()
      );
      if (release_date < rnlv) {
        release_notes_read = true;
      }
    }
  }

  console.debug("release_notes_read " + release_notes_read);

  if (release_notes_read === true) {
    $("#whats_new").css("display", "none");
  } else {
    $("#whats_new").css("display", "inline");
  }

  let balance = $("#account_balance_menu_bar");
  if (balance.length > 0) {
    let data = {};
    data["_xsrf"] = Cookies.get("_xsrf");
    $.ajax({
      type: "POST",
      accepts: "text/javascript",
      url: "/members/get/balance",
      data: data,
      dataType: "json",
      success: function (response) {
        console.log(response);
        $(".loading").hide();
        if (response.response.result != "1") {
          console.error(
            "base_webpack.js Error getting balance! " +
              "response.response.result " +
              response.response.result +
              ", errorCode " +
              response.response.errorCode +
              ", errorMsg " +
              response.response.errorMsg
          );

          return;
        }
        $("#account_balance_menu_bar").html(
          "Account ($" + response.response.balance.__value__ + ")"
        );
      }.bind(this),
      error: function (jqXHR) {
        console.error(
          "base_webpack.js Error getting balance! " +
            "jqXHR.status " +
            jqXHR.status +
            ", jqXHR.statusText " +
            jqXHR.statusText
        );

        return;
      }.bind(this),
    });
  }
});
